import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect";
import { Link, graphql  } from "gatsby"

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"

import WhySection from "../../components/sections/WhySection"
import GoogleImage from "../../components/GoogleImage"
import PageHeader from "../../components/layout/PageHeader"

import { devices } from "../../settings"

const WhyPage = styled.div`
  margin-left: 25px;

  @media ${devices.mobile} {
    margin-left: 0px;
  } 

  h3 {
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }

  img.monkey {
    width: 250px;
    margin-top: -50px;
    float: right;

    @media ${devices.mobile} {
      width: 125px;
      margin-top: -20px;
    } 
  }
`

export default function FastProduction({data}) {
  const products = data.products.edges;

  return (
    <>
      <SEO page="shipping" title="Your Sign Built in Only 5 Days" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why/", title: "Why SignMonkey"},
        { url: null, title: "5 Day Production" }
      ]}>
        <PageHeader title="Signs Built in Only 5 Days *" />

        <WhyPage>
            <GoogleImage 
              src="fast-monkey.png" 
              required 
              lazy 
              alt="Fast Monkey"
              className="monkey" 
              width={isMobile ? 125:250}
              height={isMobile ? 156:312}              
            />
            <p>
              While other electric sign builders will tell you three or four weeks for manufacturing. SignMonkey.com ships all our signs within five working days.
            </p>
            <p>
              * Due to high volume sales our Production Time on the following products is longer:
            </p>
            <ul>
              {products.map(({product}, index) => (
                  <li key={index}>
                      <Link to={"/products/" + product.url + "/"}>
                        {product.title}
                      </Link>{' '}
                      
                      ({product.production_days} Working Days)
                  </li>
              ))}              
            </ul>
                
            <h3>Lean Manufacturing</h3>
            Our production system is built on exclusive systems we have developed, that are similar to “Lean manufacturing,” that eliminate wasted time and materials to provide the highest quality product in the shortest amount of time at the lowest possible cost.
            
            <h3>Efficient Methods</h3>
            Building our signs fast, it saves us potential damages and storage space and as a bonus you get your new sign working for you faster.
        </WhyPage>

        <br />
        <br />
        <WhySection />
      </Page>
    </>
  )
}

export const pageQuery = graphql`
    query {
        products: allMysqlProducts(filter: {production_days: {gt: 5}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    production_days
                }
            }
        }
    }`